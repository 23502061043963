import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import { sizeTheme as SIZE_THEME, getThemeStyles } from 'holded/lib/styles';
import { Testimonials2Section } from 'holded/modules/cms/domain/components/testimonial';
import { defaultThemeValues, TestimonialTheme } from 'holded/modules/cms/ui/sections/testimonials/TestimonialTheme';

const NextImage = dynamic(() => import('holded/modules/cms/ui/shared/components/Image'));
const Testimonials2 = ({ testimonials, background, fontSize }: Testimonials2Section) => {
  const [themeValues, setThemeValues] = useState(defaultThemeValues);

  useEffect(() => {
    const themeStyles = getThemeStyles(TestimonialTheme.testimonial, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);

  const fontSizeValue = SIZE_THEME[fontSize];
  return (
    <>
      {testimonials?.map((item) => (
        <div key={item.id} className={`bg-white pt-8 lg:py-16`}>
          <div className={`${themeValues.background} pb-16 lg:pb-0 lg:z-10 lg:relative`}>
            <div className={`lg:mx-auto max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8`}>
              <div className="relative lg:-my-8">
                <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                  {item.image && (
                    <div className="hidden md:block aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                      <NextImage media={item.image.data} classImg={'object-cover lg:h-full lg:w-full'} />
                    </div>
                  )}
                  {item.imageMobile?.data && (
                    <div className="md:hidden aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                      <NextImage media={item.imageMobile.data} classImg={'object-cover object-center !h-full'} />
                    </div>
                  )}
                  {!item.imageMobile?.data && item.image?.data && (
                    <div className="md:hidden aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none h-full">
                      <NextImage media={item.image.data} classImg={'object-cover !h-full lg:w-full'} />
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                  {item.testimonial && (
                    <blockquote>
                      <div>
                        <svg
                          className={`h-12 w-12 ${themeValues.quoteMarkColor2}`}
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p className={`${themeValues.quoteColor} mt-6 font-medium text-h4 md:text-${fontSizeValue}`}>
                          {item.testimonial}
                        </p>
                      </div>
                      {(item.name || item.workTitle) && (
                        <footer className="mt-6">
                          <p className={`${themeValues.nameColor} text-b3 font-medium leading-6`}>{item.name}</p>
                          <p className={`${themeValues.workTitleColor2} text-b3 font-medium leading-6`}>
                            {item.workTitle}
                          </p>
                        </footer>
                      )}
                    </blockquote>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Testimonials2;
